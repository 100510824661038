<template>
  <div :class="{ 'field-error': error }">
    <label :for="name"
      >{{ label }} <span v-if="required" class="text-red-600">*</span></label
    >
    <slot />
    <div role="alert" class="mt-1" v-if="error">
      <p class="text-sm text-danger">{{ error }}</p>
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    name: String,
    label: String,
    error: String,
    required: Boolean,
  },
});
</script>

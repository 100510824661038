<template>
  <div class="flex flex-col">
    <Title :pages="pages">
    </Title>

    <div v-if="loading">loading....</div>
    <div v-else-if="workflow" class="flex-1 flex flex-col">
      <div class="py-3 px-4 sm:px-6 lg:px-8">
        <div class="grid grid-cols-12">
          <div class="col-span-2 pt-4"><label>Auto refresh every second : </label></div>
          <div>
            <Number
              class="mt-3"
              name="autoRefreshSecond"
              label=""
              min="0"
              :value="autoRefreshSecond"
              @input="autoRefreshSecond = $event.target?.value ?? 0"
            />
          </div>
          <div>
            <Button status="primary" class="ml-3 mt-3" @click="handelAutoRefreshApply()">Apply</Button>
          </div>
        </div>
        <hr class="mt-4">
        <pre>
          <code class="pre block whitespace-pre">{{logs}}</code>
        </pre>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, inject, computed } from "vue";
import { CalendarIcon } from "@heroicons/vue/solid";
import { useRoute } from "vue-router";
import Title from "@/components/common/Title";
import Button from "@/components/common/Button";
import Number from "@/components/common/fields/Number";

export default defineComponent({
  mixins: [],

  components: {
    Title,
    Button,
    Number
  },
  setup() {
    const route = useRoute();
    const $axios = inject("axios");

    const loading = ref(true);
    const workflow = ref(null);
    const logs = ref(null);

    const publish = usePublish(route.params.workflow);
    const autoRefreshSecond = 0;
    const refreshIntervalId = ref(null);

    const pages = computed(() => [
      {
        name: "Workflows",
        href: "workflows.index",
        current: false,
      },
      {
        name: workflow.value ? `${workflow.value.name}` : "View",
        href: "workflows.view",
        current: true,
      },
    ]);

    (async function () {
      loading.value = true;

      try {
        const [{ data: item }] = await Promise.all([
          $axios.get(`/workflows/${route.params.workflow}`),
        ]);

        workflow.value = item.data;
      } catch (error) {
        //
      }

      loading.value = false;
    })();

    (async function () {
      loading.value = true;

      try {
        const [{ data: item }] = await Promise.all([
          $axios.get(`/workflows/${route.params.workflow}/logs/${route.params.run_id}`),
        ]);

        logs.value = item.data;
      } catch (error) {
        //
      }
      // logs.value = "this istewt"

      loading.value = false;
    })();

    return {
      ...publish,
      handelAutoRefreshApply,
      CalendarIcon,
      pages,
      loading,
      workflow,
      logs,
      autoRefreshSecond,
      route,
      axios : $axios,
      refreshIntervalId,
    };
  },
});
function handelAutoRefreshApply() {
  let autoRefreshSecond = parseInt(this.autoRefreshSecond);
  var refreshIntervalId;

  if (autoRefreshSecond > 0) {
    this.refreshIntervalId = setInterval(() => {
      (async () => {
        this.logs
        try {
          const [{ data: item }] = await Promise.all([
            this.axios.get(`/workflows/${this.route.params.workflow}/logs/${this.route.params.run_id}`),
          ]);

          this.logs = item.data;
        } catch (error) {
          //
        }
      })();
      
    }, autoRefreshSecond*1000); 

  } else {
    clearInterval(this.refreshIntervalId);
  }
}

function usePublish(workflowId) {
  const axios = inject("axios");

  const publishing = ref(false);

  async function handlePublish() {
    publishing.value = true;

    try {
      await axios.post(`/workflows/${workflowId}/publish`);
    } catch (error) {
      //
    }

    publishing.value = false;
  }

  return {
    publishing,
    handlePublish,
  };
}
</script>

<template>
  <div
    class="
      border-b border-gray-200
      px-4
      py-4
      sm:flex sm:items-center sm:justify-between sm:px-6
      lg:px-8
    "
  >
    <div class="flex-1 min-w-0">
      <Breadcrumb :pages="pages" />
    </div>
    <div class="mt-4 flex sm:mt-0 sm:ml-4">
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Breadcrumb from "@/components/common/Breadcrumb";

export default defineComponent({
  components: {
    Breadcrumb,
  },
  props: {
    pages: Array,
  },
});
</script>

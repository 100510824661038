<template>
  <nav class="flex" aria-label="Breadcrumb">
    <ol class="flex items-center space-x-4">
      <li>
        <div>
          <router-link
            :to="{ name: 'dashboard' }"
            class="text-gray-500 hover:text-gray-600"
          >
            <HomeIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
            <span class="sr-only">Home</span>
          </router-link>
        </div>
      </li>
      <li v-for="page in pages" :key="page.name">
        <div class="flex items-center">
          <svg
            class="flex-shrink-0 h-5 w-5 text-gray-300"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
            aria-hidden="true"
          >
            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
          </svg>
          <router-link
            :to="{ name: page.href }"
            :aria-current="page.current ? 'page' : undefined"
            class="ml-4 text-sm font-medium text-gray-600 hover:text-gray-700"
            >{{ page.name }}</router-link
          >
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
import { defineComponent } from "vue";
import { HomeIcon } from "@heroicons/vue/solid";

export default defineComponent({
  components: {
    HomeIcon,
  },
  props: {
    pages: Array,
  },
});
</script>

<template>
  <component
    :is="as"
    :type="type"
    class="
      order-0
      inline-flex
      items-center
      px-4
      py-2
      border
      shadow-sm
      text-sm
      font-medium
      rounded-md
      focus:outline-none focus:ring-2 focus:ring-offset-2
      sm:order-1
      disabled:opacity-50
    "
    :class="{
      'justify-center': center,
      'text-white bg-primary hover:bg-primary-darker focus:ring-primary-dark border-transparent':
        status === 'primary',
      'text-white bg-success hover:bg-success-darker focus:ring-success-dark border-transparent':
        status === 'success',
      'text-white bg-danger hover:bg-danger-darker focus:ring-danger-dark border-transparent':
        status === 'danger',
      'text-white bg-secondary hover:bg-secondary-darker focus:ring-secondary-dark border-transparent':
        status === 'secondary',
      'bg-white text-gray-700 hover:bg-secondary-lighter focus:ring-primary border-secondary-light':
        status === 'tertiary',
    }"
  >
    <svg
      v-if="loading"
      class="animate-spin -ml-1 mr-3 h-5 w-5"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      />
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
    <slot />
  </component>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    center: Boolean,
    loading: Boolean,
    as: {
      type: String,
      default: "button",
    },
    type: {
      type: String,
      default: "button",
    },
    status: {
      type: String,
      default: "tertiary",
    },
  },
});
</script>
